import { deleteAccessToken, isLoggedIn } from "../Api/UserAuth";
import { checkIsLoggedInTrue, userLoggedInSuccess, checkIsLoggedInFalse } from "../Redux/UserAuth/Actions";

export default function CheckLogin_Service(dispatch){
    return new Promise((resolve, reject) => {
        isLoggedIn()
        .then((res) => {
            if (res.status === 200) {
                dispatch(checkIsLoggedInTrue());
                dispatch(userLoggedInSuccess(res.result));
            }
            return resolve()
        }).catch(err => {
            const { response } = err;
            if(response?.status === 401){
                deleteAccessToken();
            }
            dispatch(checkIsLoggedInFalse());
            return reject()
        })
    })
}