import ActionTypes from "./ActionTypes";

const INITAL_STATE = {
    isLoggedIn: null,
    userID: null,
    username: null,
    siteConfiguration: null,
    profiles: null,
};

const UserAuthReducer = (state = INITAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.checkIsLoggedInTrue:
            return {
                ...state,
                isLoggedIn: true,
            };
        case ActionTypes.checkIsLoggedInFalse:
            return {
                ...state,
                isLoggedIn: false,
            };
        case ActionTypes.userLoggedInSuccess:
            return {
                ...state,
                isLoggedIn: true,
                ...payload,
            };
        case ActionTypes.getSiteBasicConfig:
            return {
                ...state,
                siteConfiguration: payload,
            };
        case ActionTypes.getUserProfiles:
            return {
                ...state,
                profiles: payload,
            };
        case ActionTypes.changeUserQuality:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    setting: {
                        ...state.profile.setting,
                        defaultQuality: payload,
                    },
                },
            };
        case ActionTypes.removeUserProfiles:
            return {
                ...state,
                profiles: null,
            };
        default:
            return state;
    }
};
export default UserAuthReducer;

// case ActionTypes.checkIsLoggedIn:
//     return {
//         ...state,
//         isLoggedIn: payload
//     }
