import actionTypes from "./actionTypes";

const { GET_PROFILE_FULL_DATA, MODIFY_ACTIVITIES } = actionTypes;

const initialState = {
    fullProfileData: null,
};

const dashboardReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_PROFILE_FULL_DATA:
            return {
                ...state,
                fullProfileData: payload,
            };
        case MODIFY_ACTIVITIES:
            return {
                ...state,
                fullProfileData: {
                    ...state.fullProfileData,
                    activity: {
                        ...state.fullProfileData.activity,
                        [payload.name]: payload.count,
                    },
                },
            };
        default:
            return state;
    }
};

export default dashboardReducer;
