import ActionTypes from "./ActionTypes";

export const changePageTitle = (title) => ({
  type: ActionTypes.changePageTitle,
  payload: title,
});

export const changeDes = (des) => ({
  type: ActionTypes.changeDes,
  payload: des,
});

export const changeSiteLanguage = (code, dir) => ({
  type: ActionTypes.changeSiteLanguage,
  payload: {
    code,
    dir,
  },
});

export const changeHeaderFooterShow = (isHide) => ({
  type: ActionTypes.changeHeaderFooterShow,
  payload: isHide,
});

export const changeFooterShow = (isHide) => ({
  type: ActionTypes.changeFooterShow,
  payload: isHide,
});

export const toggleLoginAlert = (show) => ({
  type: ActionTypes.toggleLoginAlert,
  payload: show
});
