import ActionTypes from "./ActionTypes";

const INITAL_STATE = {
  siteTitle: "عربيو",
  siteDescription: null,
  pageTitle: "عربيو",
  siteLanguage: {
    dir: null,
    code: null,
  },
  hideHeaderFooter: null,
  hideFooter: false,
  showLoginAlert: false,
};

const GeneralReducer = (state = INITAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.changePageTitle:
      return {
        ...state,
        pageTitle: payload ?? "عربيو",
      };
    case ActionTypes.changeDes:
      return {
        ...state,
        siteDescription: payload,
      };
    case ActionTypes.changeSiteLanguage:
      return {
        ...state,
        siteLanguage: {
          code: payload.code ?? state.siteLanguage.code,
          dir: payload.dir ?? state.siteLanguage.dir,
        },
      };
    case ActionTypes.changeHeaderFooterShow:
      return {
        ...state,
        hideHeaderFooter: !!payload,
      };
    case ActionTypes.changeFooterShow:
      return {
        ...state,
        hideFooter: !!payload,
      };
    case ActionTypes.toggleLoginAlert:
      return {
        ...state,
        showLoginAlert: !!payload,
      };
    default:
      return state;
  }
};
export default GeneralReducer;
