import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import RoutesHelper from "./RoutesHelper";
import AsyncComponent from "../Components/Helpers/AsyncComponent";

import Header from "../Components/Layouts/Header";
import Footer from "../Components/Layouts/Footer";
import { useSelector } from "react-redux";
import AppBar from "../Components/Layouts/AppBar";
import CommonModals from "../Pages/Common";
import ApplicationModal from "../Components/General/ApplicationModal";

// Pages Routes
const Home = AsyncComponent(() => import("../Pages/Home").then((module) => module.default));
const KidDashboard = AsyncComponent(() =>
    import("../Pages/Home/KidDashboard").then((module) => module.default)
);
const Auth = AsyncComponent(() => import("../Pages/Auth/Auth").then((module) => module.default));
const NewProfile = AsyncComponent(() =>
    import("../Pages/Auth/NewProfile").then((module) => module.default)
);
const DeviceManagement = AsyncComponent(() =>
    import("../Pages/Auth/RemoveDevice").then((module) => module.default)
);
const ProfilePage = AsyncComponent(() =>
    import("../Pages/Dashboard").then((module) => module.default)
);
const SingleMovie = AsyncComponent(() =>
    import("../Pages/ContentDetail").then((module) => module.default)
);
const CastAndCrew = AsyncComponent(() =>
    import("../Pages/CastAndCrew").then((module) => module.default)
);
const Player = AsyncComponent(() =>
    import("../Pages/Player/playerRef").then((module) => module.default)
);
const Live = AsyncComponent(() => import("../Pages/Live").then((module) => module.default));
const LivePlayer = AsyncComponent(() =>
    import("../Pages/LivePlayer").then((module) => module.default)
);
const NotFound = AsyncComponent(() =>
    import("../Pages/NotFound/NotFound").then((module) => module.default)
);
const GeneralPages = AsyncComponent(() =>
    import("../Pages/General").then((module) => module.default)
);
const ActivityPage = AsyncComponent(() =>
    import("../Pages/Dashboard/components/ActivityMobilePage/ActivityMobilePage").then(
        (module) => module.default
    )
);
const SearchPage = AsyncComponent(() =>
    import("../Pages/Search/SearchPage").then((module) => module.default)
);

const CategoryPage = AsyncComponent(() =>
    import("../Pages/CategoryDetail").then((module) => module.default)
);

const SubscriptionPage = AsyncComponent(() =>
    import("../Pages/Subscriptions/SubscriptionPage").then((module) => module.default)
);

const PaymentPage = AsyncComponent(() =>
    import("../Pages/Payment/PaymentPage").then((module) => module.default)
);

const TvLoginPage = AsyncComponent(() =>
    import("../Pages/Auth/tv-login/TvLoginPage").then((module) => module.default)
);

const TvLoginPageConfirmation = AsyncComponent(() =>
    import("../Pages/Auth/tv-login/TvLoginConfirmation").then((module) => module.default)
);

const AuthPage = AsyncComponent(() =>
    import("../Pages/Auth/oAuth/OAuthPage").then((module) => module.default)
);

const Counter = AsyncComponent(() => import("../Pages/Counter").then((module) => module.default));

function PageRoutes() {
    const { pathname, search } = useLocation();
    const history = useHistory();
    const language = useSelector((state) => state.GeneralReducer.siteLanguage);
    const hideLayout = useSelector((state) => state.GeneralReducer.hideHeaderFooter);
    const authCheck = useSelector((state) => state.UserAuthReducer);

    /* ------------------------------ Side Effects ------------------------------ */
    useEffect(() => {
        localStorage.setItem("latestPath", pathname + search);
    }, [pathname, search]);

    useEffect(() => {
        language.code === "fa"
            ? document.body.classList.add("farsi-font")
            : document.body.classList.remove("farsi-font");

        if (!authCheck.isLoggedIn) {
            if (authCheck.siteConfiguration?.forceToLogin) {
                history.push(RoutesHelper("LoginURL"));
            }
        } else {
            if (!!authCheck.deviceMustRemove) {
                history.push(`${RoutesHelper("DeviceManagementURL")}${window.location.search}`);
            } else if (
                authCheck.siteConfiguration.forceToSubscription &&
                !authCheck.hasSubscription
            ) {
                history.push(`${RoutesHelper("SubscriptionURL")}${window.location.search}`);
            } else if (!authCheck.hasProfile) {
                history.push(`${RoutesHelper("RegisterURL")}${window.location.search}`);
            } else if (!authCheck.needToSelectProfile) {
                const isRegisterPage = window.location.pathname.split("/").reverse()[0];
                if (window.location.search.includes("tvCode")) {
                    history.push(`${RoutesHelper("TvLogin")}${window.location.search}`);
                } else if (isRegisterPage === "register" || isRegisterPage === "auth") {
                    history.push(RoutesHelper("home"));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCheck.siteConfiguration, authCheck.isLoggedIn, authCheck.hasSubscription]);

    return (
        <React.Fragment>
            {!hideLayout && <Header />}
            <div
                className={`d-flex flex-column flex-grow-1 ${
                    language.dir === "ltr" ? "ltr-mode" : "rtl-mode"
                } pb-sm-none pb-nav`}
            >
                <CommonModals />
                <Switch>
                    <Route path={RoutesHelper("HomeURL")} exact component={Home} />
                    <Route path={RoutesHelper("kids")} exact component={KidDashboard} />
                    <Route
                        path={`${RoutesHelper("OAuth")}/:serviceName`}
                        exact
                        component={AuthPage}
                    />
                    <Route path={`${RoutesHelper("LoginURL")}`} exact component={Auth} />
                    <Route path={`${RoutesHelper("RegisterURL")}`} exact component={NewProfile} />
                    <Route
                        path={`${RoutesHelper("DeviceManagementURL")}`}
                        exact
                        component={DeviceManagement}
                    />
                    <Route
                        path={`${RoutesHelper("ProfileURL")}/:section`}
                        exact
                        component={ProfilePage}
                    />
                    <Route
                        path={`${RoutesHelper("SingleMovie")}/:permalink`}
                        exact
                        component={SingleMovie}
                    />
                    <Route
                        path={`${RoutesHelper("CastAndCrew")}/:permalink`}
                        exact
                        component={CastAndCrew}
                    />
                    <Route
                        path={`${RoutesHelper("Player")}/:playPermaLink`}
                        exact
                        component={Player}
                    />
                    <Route path={`${RoutesHelper("Live")}`} exact component={Live} />
                    <Route
                        path={`${RoutesHelper("LivePlayer")}/:liveId`}
                        exact
                        component={LivePlayer}
                    />
                    <Route
                        path={`${RoutesHelper("General")}/:permalink`}
                        exact
                        component={GeneralPages}
                    />
                    <Route
                        path={`${RoutesHelper("SearchURL")}`}
                        exact={true}
                        component={SearchPage}
                    />
                    <Route
                        path={`${RoutesHelper("CategoryURL")}/:categoryId`}
                        exact={false}
                        component={CategoryPage}
                    />
                    <Route
                        path={`${RoutesHelper("PaymentURL")}/:token`}
                        exact
                        component={PaymentPage}
                    />
                    <Route
                        needAuth={true}
                        path={RoutesHelper("TvLogin")}
                        exact
                        component={TvLoginPage}
                    />
                    <Route
                        needAuth={true}
                        path={RoutesHelper("TvLoginConfirmation")}
                        exact
                        component={TvLoginPageConfirmation}
                    />
                    <Route
                        needAuth={true}
                        path={`${RoutesHelper("SubscriptionURL")}`}
                        exact
                        component={SubscriptionPage}
                    />
                    <Route
                        path={`${RoutesHelper("ActivityURL")}/:activityName`}
                        exact
                        component={ActivityPage}
                    />
                    <Route path={"/*"} component={NotFound} />
                </Switch>
            </div>
            {pathname !== RoutesHelper("DeviceManagementURL") &&
                pathname !== RoutesHelper("RegisterURL") &&
                !pathname.includes("/v/") && // when user is in the Player Route
                !pathname.includes("/LivePlayer/") && // when user is in the Player Route
                pathname !== RoutesHelper("LoginURL") && (
                    <div className="d-lg-none d-block">
                        <AppBar />
                    </div>
                )}
            {!hideLayout && <Footer />}
            <ApplicationModal />
        </React.Fragment>
    );
}

export default PageRoutes;
