import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AlertView from "../../Components/General/AlertView/AlertView.modal";
import { toggleLoginAlert } from "../../Redux/General/Actions";
import RoutesHelper from "../../Routes/RoutesHelper";



function CommonModals(props) {

    const dispatch = useDispatch();
    const history = useHistory()

    const { showLoginAlert } = useSelector(state => state.GeneralReducer);

    const handleCloseLoginAlert = () => {
        dispatch(toggleLoginAlert(false));
    }
    const handleClickOkLoginAlert = () => {
        handleCloseLoginAlert();
        history.push({
            pathname: RoutesHelper("LoginURL"),
            search: `?from=${history.location.pathname}#comments`,
        });
    }

    return (
        <>
            <AlertView
                onOkText={t("Login")}
                textMessage={t("You must Login First")}
                show={showLoginAlert}
                onClickCancel={handleCloseLoginAlert}
                onClickOk={handleClickOkLoginAlert}
            />
        </>
    )
}

export default CommonModals;