const ActionTypes = {
    checkIsLoggedInTrue: "CHECK_IS_LOGGED_IN_TRUE",
    checkIsLoggedInFalse: "CHECK_IS_LOGGED_IN_FALSE",
    userLoggedInSuccess: "USER_LOGGED_IN_SUCCESS",
    getSiteBasicConfig: "GET_SITE_BASIC_CONFIGURATION",
    getUserProfiles: "GET_USER_PROFILES",
    removeUserProfiles: "REMOVE_USER_PROFILES",
    changeUserQuality: "CHANGE-USER-QUALITY",
};
export default ActionTypes;
