import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { ReactComponent as HomeLogo } from "../../assets/img/Home.svg";
import { ReactComponent as HomeLogoActive } from "../../assets/img/Home_active.svg";
import { ReactComponent as SearchLogo } from "../../assets/img/Search.svg";
import { ReactComponent as SearchLogoActive } from "../../assets/img/search_active.svg";
import { ReactComponent as LiveTvLogo } from "../../assets/img/Live-Icon-white.svg";
import { ReactComponent as KidsLogo } from "../../assets/img/kidsIcon.svg";
import LoginLogo from "../../assets/img/userNotlogin.svg";
import LoginLogoActive from "../../assets/img/userNotloginActive.svg";
import RoutesHelper from "../../Routes/RoutesHelper";
import { useSelector } from "react-redux";
import { t } from "i18next";
import SubscriptionButtonMobile from "../General/SubscriptionButtonMobile";

const AppBarButton = (props) => {
    const { label, icon, activeIcon, path, withImage } = props;
    const websitePath = window.location.pathname;

    const [iconState, setIconState] = useState({
        icon,
        isActive: false,
    });

    useEffect(() => {
        if ((websitePath.includes("profile") && path.includes("profile")) || websitePath === path) {
            setIconState({
                icon: activeIcon,
                isActive: true,
            });
        } else {
            setIconState({
                icon,
                isActive: false,
            });
        }
    }, [websitePath]);

    return (
        <NavLink
            to={path}
            className="d-flex flex-column justify-content-center align-items-center mx-auto"
        >
            <span className={`${withImage ? "with-image-icon-holder" : "icon-holder"}`}>
                {withImage ? <img src={iconState.icon} alt="app-bar-icon" /> : iconState.icon}
            </span>
            <span
                className={`app-bar-label pt-2 ${iconState.isActive && "activeMobileFooterIcon"}`}
            >
                {label}
            </span>
        </NavLink>
    );
};

AppBarButton.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    path: PropTypes.string,
};

const AppBar = () => {
    const userProfileData = useSelector((state) => state.UserAuthReducer.profile);
    const userIsLoggedIn = useSelector((state) => state.UserAuthReducer.isLoggedIn);
    const siteConfiguration = useSelector((state) => state.UserAuthReducer.siteConfiguration);
    const appBarLinks = [
        {
            label: t("Home"),
            icon: <HomeLogo />,
            activeIcon: <HomeLogoActive />,
            path: RoutesHelper("HomeURL"),
        },
        {
            label: t("Search"),
            icon: <SearchLogo />,
            activeIcon: <SearchLogoActive />,
            path: RoutesHelper("SearchURL"),
        },
        // {
        //     label: t("live"),
        //     icon: <LiveTvLogo />,
        //     path: RoutesHelper("Live"),
        // },
        // {
        //     label: t("Kid"),
        //     icon: <KidsLogo />,
        //     path: "#",
        // },
    ];

    const getLanguage = JSON.parse(localStorage.getItem("lang"));
    const getDir = getLanguage?.["dir"];

    return (
        <div
            className="footer-bar d-flex justify-content-md-center position-fixed bottom-0 w-100 py-2"
            style={{ direction: getDir === "rtl" ? "rtl" : "ltr" }}
        >
            {appBarLinks.map((link, index) => (
                <AppBarButton
                    key={index}
                    icon={link.icon}
                    activeIcon={link.activeIcon}
                    label={link.label}
                    path={link.path}
                />
            ))}
            {siteConfiguration?.hasLogin && (
                <AppBarButton
                    icon={userProfileData?.image ?? LoginLogo}
                    activeIcon={userProfileData?.image ?? LoginLogo}
                    label={userIsLoggedIn ? t("Profile") : t("login")}
                    path={userIsLoggedIn ? RoutesHelper("DashboardURL") : RoutesHelper("LoginURL")}
                    withImage
                />
            )}
            {/* <SubscriptionButtonMobile /> */}
        </div>
    );
};

export default AppBar;
