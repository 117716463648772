import actionTypes from "./actionTypes";

const { SELECTED_SEASON_ID } = actionTypes;

const INITIAL_STATE = {
    selectedSeasonId: null,
};

const contentDetailReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SELECTED_SEASON_ID:
            return { ...state, selectedSeasonId: payload };

        default:
            return state;
    }
};

export default contentDetailReducer;
