const actionTypes = {
  HEADER_LEFT_SIDE: "HEADER_LEFT_SIDE",
  HEADER_RIGHT_SIDE: "HEADER_RIGHT_SIDE",
  SHOW_LOGO: "SHOW_LOGO",
  SHOW_LOGO_CENTER: "SHOW_LOGO_CENTER",
  DEFAULT_HEADER: "DEFAULT_HEADER",
  LOGO_ROUTE: "LOGO_ROUTE",
};

export default actionTypes;
