import React, { useEffect, useState } from "react";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { useSelector, useDispatch } from "react-redux";
import RoutesHelper from "../../Routes/RoutesHelper";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import { defaultHeader } from "../../Redux/HeaderLayout/action";

function Header(props) {
    const { pathname } = useLocation();

    /* ---------------------------------- Redux --------------------------------- */
    const dispatch = useDispatch();

    const { siteConfiguration } = useSelector((state) => state.UserAuthReducer);
    const pageDetails = useSelector((state) => state.GeneralReducer);
    const headerLayout = useSelector((state) => state.headerReducer);

    /* --------------------------- States & Functions --------------------------- */
    const [ShowHoverMenu, setShowHoverMenu] = useState(
        window.innerWidth > 991 ? { show: true } : null
    );

    // start My Account handler
    const [showMyAccount, setShowMyAccount] = useState(false);
    const handleCloseMyAccount = () => setShowMyAccount(false);
    const handleShowMyAccount = () => setShowMyAccount(true);
    // end My Account handler

    const headerRef = React.useRef(null);
    useEffect(() => {
        // window.onscroll = function() {};
        // const header = document.getElementById("navbar-vod");
        let sticky = headerRef.current?.offsetTop;
        function myFunction() {
            if (headerRef.current) {
                if (window.pageYOffset > sticky) {
                    headerRef.current.classList.add("navbar-vod-sticky");
                } else {
                    headerRef.current.classList.remove("navbar-vod-sticky");
                }
            }
        }
        window.addEventListener("scroll", function () {
            myFunction();
        });
    }, []);

    useEffect(() => {
        window.addEventListener(
            "resize",
            function (event) {
                setShowHoverMenu(window.innerWidth > 991 ? { show: true } : null);
            },
            true
        );
    }, []);

    useEffect(() => {
        dispatch(defaultHeader());
    }, [pathname]);

    let logo = null;
    if (siteConfiguration?.hasLogoApp) {
        logo = siteConfiguration?.logoApp;
    }

    return (
        <>
            <header>
                <hgroup id="site-title">
                    <h1>{pageDetails.pageTitle}</h1>
                    <h2>{pageDetails.siteDescription}</h2>
                </hgroup>
            </header>
            <Navbar
                variant="dark"
                className={`navbar-vod vod-transition vod-glass autoDirection`}
                id={"navbar-vod"}
                ref={headerRef}
            >
                <Container fluid className="">
                    {headerLayout.leftSideOfHeader}
                    {headerLayout.showLogo && logo && (
                        <HashLink
                            smooth
                            to={RoutesHelper("HomeURL")}
                            className={`navbar-brand  me-auto ms-auto`}
                            title={pageDetails.siteTitle}
                        >
                            <img
                                src={logo}
                                alt={pageDetails.siteTitle}
                                className={`vod-logo-nav vod-transition  ${
                                    headerLayout.showLogoCenter ? "vod-logo-nav-center" : ""
                                }`}
                            />
                        </HashLink>
                    )}
                    {headerLayout.rightSideOfHeader}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </Container>
            </Navbar>
            <Offcanvas
                show={showMyAccount}
                placement={"end"}
                onHide={handleCloseMyAccount}
                className="MyAccountPopup"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    Some text as placeholder. In real life you can have the elements you have
                    chosen. Like, text, images, lists, etc.
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
export default Header;
