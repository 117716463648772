import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { t } from "i18next";
import RoutesHelper from "../../Routes/RoutesHelper";
import dailyMotion from "./../../assets/img/social/dailymotion.svg";
import facebook from "./../../assets/img/social/facebook.svg";
import instagram from "./../../assets/img/social/instagram.svg";
import telegram from "./../../assets/img/social/telegram.svg";
import tiktok from "./../../assets/img/social/tiktok.svg";
import x from "./../../assets/img/social/x.svg";
import youtube from "./../../assets/img/social/youtube.svg";
import ApplicationBtn from "../General/ApplicationBtn";

const socialItems = [
    { title: t("facebook"), img: facebook, url: "https://www.facebook.com/arabeoTv" },
    { title: t("x"), img: x, url: "https://twitter.com/ArabeoTV" },
    { title: t("instagram"), img: instagram, url: "https://www.instagram.com/arabeoTv" },
    { title: t("telegram"), img: telegram, url: "https://t.me/ArabeoTv" },
    { title: t("tiktok"), img: tiktok, url: "https://www.tiktok.com/@arabeoTv" },
    { title: t("youtube"), img: youtube, url: "https://www.youtube.com/@arabeotv" },
    { title: t("dailyMotion"), img: dailyMotion, url: "https://www.dailymotion.com/arabeotv" },
];

function Footer() {
    // const FooterStatus = useSelector(state => state.GeneralReducer.hideFooter);
    const { siteConfiguration } = useSelector((state) => state.UserAuthReducer);
    let logo = null;
    if (siteConfiguration?.hasLogoApp) {
        logo = siteConfiguration?.logoApp;
    }

    let menuItems = [
        // {
        //     title: t("Home"),
        //     url: "/",
        // },
        // {
        //     title: t("Contact Us"),
        //     url: `${RoutesHelper("General")}/contact_us`,
        // },
        // {
        //     title: t("Terms and Conditions"),
        //     url: `${RoutesHelper("General")}/terms_conditions`,
        // },
        // {
        //     title: t("Privacy policy"),
        //     url: `${RoutesHelper("General")}/privacy_policy`,
        // },
    ];

    const getLanguage = JSON.parse(localStorage.getItem("lang"));
    const getDir = getLanguage?.["dir"];

    return (
        <footer className="d-md-block d-none footer-section">
            <div className="container" id="footer">
                <div className="row">
                    <div className="col-12 text-center">
                        <ul
                            className="footer-menu"
                            style={{ direction: getDir === "rtl" ? "rtl" : "ltr" }}
                        >
                            {menuItems.map((item, index) => (
                                <li className="ms-4" key={index}>
                                    <HashLink smooth to={`${item.url}`} title={item.title}>
                                        {item.title}
                                    </HashLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="footer-social">
                            {socialItems.map((item, index) => (
                                <a
                                    target="_blank"
                                    key={index}
                                    href={`${item.url}`}
                                    title={item.title}
                                >
                                    <img src={item.img} alt={item.title} />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                <ApplicationBtn />
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="footer-logoFrame">
                            <img src={logo} className="footer-logoImg" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <p className="footer-copyright">{t("All rights reserved © Arabeo 2023")}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
