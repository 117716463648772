import React from "react";
import PropTypes from "prop-types";
import { t } from "i18next";

const GradiantButton = (props) => {
  const {
    onClick,
    type,
    disabled,
    loading,
    buttonShape = "filled",
    className = "",
  } = props;

  let shape = "";
  if (buttonShape === "filled") {
    shape = "btn-danger";
  } else if (buttonShape === "outlined") {
    shape = "btn-danger-outlined";
  } else if (buttonShape === "text") {
    shape = "btn-danger-text";
  }

  return (
    <button
      className={`btn-login-vod ${shape} ${
        disabled ? "disabled-btn" : ""
      } ${className}`}
      onClick={disabled || loading ? null : onClick}
      type={type ?? "button"}
      disabled={disabled || loading}
    >
      {loading ? (
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">{t("Loading...")}</span>
        </div>
      ) : (
        props.children
      )}
    </button>
  );
};

GradiantButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["submit", "button", "reset"]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonShape: PropTypes.oneOf(["filled", "outlined", "text"]),
};

export default GradiantButton;
