import actionTypes from "./actionTypes";

const {
  HEADER_LEFT_SIDE,
  HEADER_RIGHT_SIDE,
  SHOW_LOGO,
  SHOW_LOGO_CENTER,
  DEFAULT_HEADER,
  LOGO_ROUTE,
} = actionTypes;

export const getRightSideOfHeader = (payload) => ({
  type: HEADER_RIGHT_SIDE,
  payload,
});

export const getLeftSideOfHeader = (payload) => ({
  type: HEADER_LEFT_SIDE,
  payload,
});

export const showLogo = (payload) => ({
  type: SHOW_LOGO,
  payload,
});

export const showLogoCenter = (payload) => ({
  type: SHOW_LOGO_CENTER,
  payload,
});

export const setLogoRoute = (payload) => ({
  type: LOGO_ROUTE,
  payload,
});

export const defaultHeader = () => ({
  type: DEFAULT_HEADER,
});
