import axios from "axios";
import { toast } from "react-toastify";
import { detectLangCodeFromURL } from "../helper/helperFunctions";
import { getAccessToken } from "./UserAuth";

function VodApi(method, url, data = null, signal = null, showError = true) {
    const config = {
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            Authorization: `Bearer ${getAccessToken()}`,
            "Cache-Control": "no-cache",
        },
    };
    return new Promise((resolve, reject) => {
        axios({
            ...config,
            method,
            url,
            data,
            signal,
        }).then(resolve)
            .catch((err) => {
                if(showError){
                    handleShowError(err);
                }
                reject(err);
            });
    });
}

axios.interceptors.request.use(function (config) {
    const languageFromLocal = localStorage.getItem("lang");
    const parsedLang = JSON.parse(languageFromLocal);
    const langCode = parsedLang?.code;

    if (langCode) {
        let url = config.url;
        if (!url.includes("language=")) {
            if (url.includes("?")) {
                config.url += `&language=${langCode}`;
            } else {
                config.url += `?language=${langCode}`;
            }
        }
    }
    return config;
});

axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        
        return Promise.reject(error);
    }
);

const handleShowError = (error) => {
    const { response } = error;
        if(response && response?.data && response?.data?.status){
            switch (response?.data?.status) {
                case 404:
                    toast.error("404: Not Found!");
                    break;
                case 401: 
                    break;
                default:
                    response.data.message.map((errorMessage) =>
                        toast.error(errorMessage)
                    );
                    break;
            }

        }
}

export default VodApi;
