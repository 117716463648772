import actionTypes from "./actionTypes";

const {
    HEADER_RIGHT_SIDE,
    HEADER_LEFT_SIDE,
    SHOW_LOGO,
    SHOW_LOGO_CENTER,
    DEFAULT_HEADER,
    LOGO_ROUTE,
} = actionTypes;

const INITIAL_STATE = {
    leftSideOfHeader: <div></div>,
    rightSideOfHeader: <div></div>,
    showLogo: false,
    showLogoCenter: false,
    logoRoute: "/",
};

const headerReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case HEADER_RIGHT_SIDE:
            return { ...state, rightSideOfHeader: payload };

        case HEADER_LEFT_SIDE:
            return { ...state, leftSideOfHeader: payload };

        case SHOW_LOGO:
            return { ...state, showLogo: payload };

        case SHOW_LOGO_CENTER:
            return { ...state, showLogoCenter: payload };

        case LOGO_ROUTE:
            return { ...state, logoRoute: payload };

        case DEFAULT_HEADER:
            return { ...state, ...INITIAL_STATE };
        default:
            return state;
    }
};

export default headerReducer;
