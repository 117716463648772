import React, { useEffect } from 'react'
import { useParams } from "react-router-dom";
import { GetOAuthLink_API } from '../../Api/UserAuth';
import useQuery from '../../hooks/useQuery';

export default function AuthOTPRedirect(props) {
    const query = useQuery();

    const { service } = useParams();

    useEffect(() => {
        const queryItems = query.get("fromWeb");
        GetOAuthLink_API(service).then(res => {
            let url = res.url;
            if(queryItems){
                url += '&state=fromWeb';
            }

            window.location.href = url;
        }).catch(err => { })
    }, [])

    return (
        <div>
            Redirect to {service}
        </div>
    )
}
