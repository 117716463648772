import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";

import RoutesHelper from "./Routes/RoutesHelper";

import App from "./App";
import AuthOTPRedirect from "./Pages/Auth/AuthOTPRedirect";

function BaseApp() {

    return (
        <BrowserRouter>
            <Switch>
                <Route path={`${RoutesHelper("redirectToOAuth")}/:service`} exact component={AuthOTPRedirect} />

                <Route path={"/*"} component={App} />
            </Switch>ّ
        </BrowserRouter>
    );
}

export default BaseApp;
