import React, { useEffect } from "react";
import "./../../assets/css/loading.css";
import "./../../assets/css/three-dots.css";
import { changeFooterShow } from "../../Redux/General/Actions";
import { useDispatch } from "react-redux";
import RedLoading from "../General/RedLoading/RedLoading";

function Loading() {
  const dispatch = useDispatch();
  // const { pathname } = useLocation();

  useEffect(() => {
    dispatch(changeFooterShow(true));

    return () => {
      dispatch(changeFooterShow(false));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-grow-1">
        {/* <div className="dot-revolution"/> */}
        <RedLoading />
      </div>
    </>
  );
}

export default Loading;
