import React from "react";
import PropTypes from "prop-types";
import CustomModal from "../Modal/Modal";
import GradiantButton from "../GradiantButton.component";

const AlertView = (props) => {
    const {
        show,
        textMessage,
        onClickOk,
        onClickCancel,
        onOkText,
        onCancelText,
    } = props;
    return (
        <CustomModal
            show={show}
            closeButton={false}
            modalBodyClassName="p-0 rounded"
        >
            <div className="d-flex flex-column justify-content-center align-items-center bg-dark py-3">
                <h5 className="mb-5 mt-2">{textMessage}</h5>
                <div className="w-75 mx-auto">
                    <div className="d-flex flex-row-reverse justify-content-between align-items-center">
                        <GradiantButton
                            buttonShape="filled"
                            className="mx-2 py-2"
                            onClick={onClickOk}
                        >
                            {onOkText ?? "ok"}
                        </GradiantButton>
                        <GradiantButton
                            buttonShape="outlined"
                            className="mt-0 py-2"
                            onClick={onClickCancel}
                        >
                            {onCancelText ?? "Cancel"}
                        </GradiantButton>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};

AlertView.propTypes = {
    show: PropTypes.bool,
    textMessage: PropTypes.string,
    onClickOk: PropTypes.func,
    onClickCancel: PropTypes.func,
    onOkText: PropTypes.string,
    onCancelText: PropTypes.string,
};

export default AlertView;
